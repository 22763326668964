import { getSnowplowEventPayload, logAnalyticsEvent } from 'Services/analytics/analytics';
import { ANALYTICS_CATEGORIES, SNOWPLOW_EVENTS } from 'Services/analytics/analytics.const';

const { VEHICLE_DETAILS } = ANALYTICS_CATEGORIES;

const { CLICK } = SNOWPLOW_EVENTS;

export const triggerGradingModalOpenedOrClosedEvent = (isOpen: boolean): void => {
	const eventPayload = getSnowplowEventPayload({
		category: VEHICLE_DETAILS,
		name: `dlr_grading_modal_${isOpen ? 'opened' : 'closed'}`,
		schemaName: CLICK.SCHEMA,
		version: CLICK.VERSION,
	});

	logAnalyticsEvent({ eventPayload });
};
