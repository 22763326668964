import type { ListType } from 'Types/listType';
import type { SaleTimes } from 'Types/saleTimes';
import { LIST_TYPES } from 'Utilities/consts';

interface GetInitialListTypeProps {
	listType: ListType;
	saleTimes: SaleTimes;
}

export const sanitiseListType = (listType: ListType): ListType | undefined => {
	const isListTypeArray = Array.isArray(listType);

	if (isListTypeArray) {
		return undefined;
	}

	return Object.values(LIST_TYPES).find((value) => value === listType);
};

export const getInitialListType = ({ listType, saleTimes }: GetInitialListTypeProps): ListType => {
	const sanitisedListType = sanitiseListType(listType);

	if (sanitisedListType) {
		return sanitisedListType;
	}

	const { isSaleActive } = saleTimes || {};
	const listTypeBasedOnSaleTime = isSaleActive ? LIST_TYPES.auction : LIST_TYPES.buyItNow;

	return sanitisedListType || listTypeBasedOnSaleTime;
};
