import { useContext } from 'react';
import type { StoreApi } from 'zustand';
import { useStore } from 'zustand';
import { devtools } from 'zustand/middleware';

import { resetValues, setValue, setValues, unsetValue } from 'Stores/FilterStore/FilterStoreHelpers';
import { createGenericStoreWithDevtools } from 'Stores/store';
import { FilterContext } from 'Stores/StoresContext/StoresContext';
import type { Filter } from 'Types/filter';
import { isDev } from 'Utilities/helpers';
import useSortOrder from 'Utilities/hooks/vehicleList/useSortOrder/useSortOrder';

import config from './VehicleListFilterConfig';
import type {
	FilterConfig,
	FilterStore,
	InitialFilterStoreValues,
	SetDefaultConfig,
	SetFilter,
	SetFilters,
	SetHasUserSetSort,
	SetIsFiltersMenuOpen,
	SetOnChange,
	SetVehicleCount,
} from './VehicleListFilterStore.types';

const createFilterStore = ({ filters, hasUserSetSort }: InitialFilterStoreValues) =>
	devtools<FilterStore>(
		(set, get) => ({
			collapsedFilters: {},
			config,
			filters,
			hasUserSetSort,
			isFiltersMenuOpen: false,
			onChange: () => undefined,
			resetValues: resetValues(set, get),
			setDefaultConfig: (field: (typeof config)[keyof typeof config]) => {
				const { config: defaultConfig } = get();
				const updatedConfig = Object.assign(defaultConfig, { [field.id]: field });
				set(() => ({ config: updatedConfig }));
			},
			setHasUserSetSort: (hasUserSetSortValue) => {
				set(() => ({ hasUserSetSort: hasUserSetSortValue }), false, { hasUserSetSort, type: 'setHasUserSetSort' });
			},
			setIsFilterCollapsed: ({ expanded, id }) => {
				set((state) => ({
					collapsedFilters: { ...state.collapsedFilters, [id]: !expanded },
				}));
			},
			setIsFiltersMenuOpen: (isFiltersMenuOpen) => {
				set(() => ({ isFiltersMenuOpen }), false, { isFiltersMenuOpen, type: 'setIsFiltersMenuOpen' });
			},
			setOnChange: (onChange) => {
				set(() => ({ onChange }), false, { onChange, type: 'setOnChange' });
			},
			setValue: setValue(set, get),
			setValues: setValues(set, get),
			setVehicleCount: (vehicleCount) => {
				set(() => ({ vehicleCount }), false, { type: 'setVehicleCount', vehicleCount });
			},
			unsetValue: unsetValue(set),
			valueCount: 0,
			vehicleCount: 0,
		}),
		{ enabled: isDev, name: 'VehicleListFilterStore' },
	);

export const createFilterStoreWithInitial = (initial: InitialFilterStoreValues): StoreApi<FilterStore> =>
	createGenericStoreWithDevtools(createFilterStore(initial));

export const useVehicleFilterStore = <T>(selector: (state: FilterStore) => T, equalityFn?: () => boolean): T => {
	const store = useContext(FilterContext);
	if (!store) {
		throw new Error('Missing FilterContext.Provider in the tree');
	}
	return useStore(store, selector, equalityFn);
};

export const useFilters = (): Filter => {
	const filters = useVehicleFilterStore((store) => store.filters);
	const { order, sort } = useSortOrder();
	// Object assign here is required to prevent memory leak on spread
	const filtersWithAdjustedSortOrder = Object.assign(filters, { order, sort });
	return filtersWithAdjustedSortOrder;
};
export const useAppliedFiltersCount = (): number => useVehicleFilterStore((store) => store.valueCount);
export const useFiltersConfig = (): FilterConfig => useVehicleFilterStore((store) => store.config);
export const useIsFiltersMenuOpen = (): boolean => useVehicleFilterStore((store) => store.isFiltersMenuOpen);
export const useVehicleCount = (): number => useVehicleFilterStore((store) => store.vehicleCount);
export const useHasUserSetSort = (): boolean => useVehicleFilterStore((store) => store.hasUserSetSort);

export const useResetFilters = (): Noop => useVehicleFilterStore((store) => store.resetValues);
export const useSetOnChange = (): SetOnChange => useVehicleFilterStore((store) => store.setOnChange);
export const useSetVehicleCount = (): SetVehicleCount => useVehicleFilterStore((store) => store.setVehicleCount);
export const useSetDefaultConfig = (): SetDefaultConfig => useVehicleFilterStore((store) => store.setDefaultConfig);
export const useSetHasUserSetSort = (): SetHasUserSetSort => useVehicleFilterStore((store) => store.setHasUserSetSort);
export const useSetFilter = (): SetFilter => useVehicleFilterStore((store) => store.setValue);
export const useSetFilters = (): SetFilters => useVehicleFilterStore((store) => store.setValues);
export const useSetIsFiltersMenuOpen = (): SetIsFiltersMenuOpen =>
	useVehicleFilterStore((store) => store.setIsFiltersMenuOpen);

export default useVehicleFilterStore;
