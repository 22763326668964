import { isIE } from '../../utilities/helpers';
import { abortRouteChange } from '../RouterMiddleware/RouterMiddleware.helpers';

export const UNSUPPORTED_BROWSER_ALLOWED_ROUTES = [
	'/',
	'/#howitworks',
	'/cookies',
	'/contact-us',
	'/terms',
	'/privacy',
];

const isAllowedUnsupportedBrowserRoute = (url) => UNSUPPORTED_BROWSER_ALLOWED_ROUTES.includes(url);

export const isUnsupportedBrowser = (userAgent) => isIE(userAgent);

export const shouldForwardToUnsupportedBrowserPage = (url, userAgent) =>
	isUnsupportedBrowser(userAgent) && !isAllowedUnsupportedBrowserRoute(url) && url !== '/unsupported-browser';

export const handleUnsupportedBrowser = ({ url }) => {
	if (shouldForwardToUnsupportedBrowserPage(url)) {
		abortRouteChange({
			routerPushArgs: [
				'/unsupported-browser',
				'/unsupported-browser',
				{
					shallow: true,
				},
			],
		});
	}
};
