import { useContext } from 'react';
import type { ReactSDKClient } from '@optimizely/react-sdk';
import { OptimizelyContext } from '@optimizely/react-sdk';

type Optimizely = ReactSDKClient | null;

export const useOptimizely = (): Optimizely => {
	const context = useContext(OptimizelyContext);
	if (!context) {
		throw new Error('useOptimizely must be used within an OptimizelyProvider');
	}
	return context?.optimizely;
};
