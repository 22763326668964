import { API_ROUTES } from 'Utilities/routes';

import axios from '../axios';

import type { BrazeAuthData } from './braze.types';

export const brazeAuth = async (): Promise<BrazeAuthData> => {
	const { data } = await axios.post(API_ROUTES.BRAZE_AUTH);
	return data;
};
