import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import type { AxiosError } from 'axios';
import { useRouter } from 'next/router';

import { logger } from 'Services/logger/logger';
import { useRefetchSaleTimes } from 'Stores/SaleTimesStore/SaleTimesStore';
import { TIME_IN_MS } from 'Utilities/consts';
import useIsAuth from 'Utilities/hooks/useIsAuth';

export const SaleTimeSynchroniser: React.FC = () => {
	const { pathname } = useRouter();
	const isAuth = useIsAuth();
	const refetchSaleTimes = useRefetchSaleTimes();

	// This query refetches the sale times every 10 minutes and on tab focus
	const { refetch } = useQuery({
		enabled: isAuth,
		onError: (error: AxiosError) => {
			const message = error?.message || 'There was an issue with fetching sale times';
			logger.error({
				context: { extra: { info: message } },
				error,
				message,
				scope: 'SaleTimeSynchroniser',
			});
		},
		queryFn: refetchSaleTimes,
		queryKey: ['saleTimes'],
		refetchInterval: TIME_IN_MS.THIRTY_MINUTES,
		refetchOnWindowFocus: 'always',
		staleTime: Infinity,
	});

	useEffect(() => {
		// This refetches the sale times on route change
		if (isAuth) {
			refetch();
		}
	}, [pathname, refetch, isAuth]);

	return null;
};
