import type { OptimizelyDecisionEventProps, OptimizelyTrackEventProps } from 'Services/optimizely/optimizely';

import { getSnowplowEventPayload, logAnalyticsEvent } from '../analytics';
import { SNOWPLOW_EVENTS } from '../analytics.const';

export const triggerOptimizelyDecisionEvent = (decision: OptimizelyDecisionEventProps): void => {
	const eventPayload = getSnowplowEventPayload({
		schemaName: SNOWPLOW_EVENTS.OPTIMIZELY_DECISION.SCHEMA,
		version: SNOWPLOW_EVENTS.OPTIMIZELY_DECISION.VERSION,
		...decision,
	});

	logAnalyticsEvent({ eventPayload });
};

export const triggerOptimizelyTrackingEvent = (tracking: OptimizelyTrackEventProps): void => {
	const eventPayload = getSnowplowEventPayload({
		schemaName: SNOWPLOW_EVENTS.OPTIMIZELY_EVENT.SCHEMA,
		version: SNOWPLOW_EVENTS.OPTIMIZELY_EVENT.VERSION,
		...tracking,
	});

	logAnalyticsEvent({ eventPayload });
};
