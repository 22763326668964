import { logAnalyticsEvents } from 'Services/analytics/analytics';

import { createSnowplowEvents } from './internal';
import type { KycAnalyticsEvent } from './types';

export const triggerKycAnalyticsEvents = (events: KycAnalyticsEvent[] = []): void => {
	if (!events?.length) {
		return;
	}

	logAnalyticsEvents([...createSnowplowEvents(events)]);
};
