import type { NextWebVitalsMetric } from 'next/app';

import { logger } from 'Services/logger/logger';

import { isMobileDevice } from './isMobileDevice';

/*
 *  According to study (https://www.browserstack.com/guide/how-fast-should-a-website-load) in 2023,
 * The average page load time on desktop is 2.5 seconds and 8.6 seconds on mobile, according to a
 * survey (https://www.tooltester.com/en/blog/website-loading-time-statistics)  of the top 100 websites globally.
 */
export const MOBILE_HIGH_LOAD_TIME_IN_MS = 9000;
export const DESKTOP_HIGH_LOAD_TIME_IN_MS = 3000;
const METRICS_TO_TRACK = ['Next.js-route-change-to-render', 'Next.js-render'];

export const isMetricTimeHigh = (loadTime: NextWebVitalsMetric['value']): boolean => {
	const highLoadTime = isMobileDevice() ? MOBILE_HIGH_LOAD_TIME_IN_MS : DESKTOP_HIGH_LOAD_TIME_IN_MS;
	return loadTime > highLoadTime;
};

export const validateLoadTime = (metric: NextWebVitalsMetric): void => {
	const loadTimeInMs = metric.value;
	const loadTimeInS = loadTimeInMs / 1000;
	const isMobile = isMobileDevice();
	if (!isMetricTimeHigh(loadTimeInMs)) {
		return;
	}

	const message = `Load time is high on ${isMobile ? 'mobile' : 'desktop'} device`;

	logger.warn({
		context: {
			extra: {
				loadTime: `${loadTimeInS}s`,
				metricName: metric.name,
			},
		},
		error: new Error(message),
		message,
		scope: 'webVitals validateLoadTime',
	});
};

export const handleWebVitals = (metric: NextWebVitalsMetric): void => {
	if (METRICS_TO_TRACK.includes(metric.name)) {
		validateLoadTime(metric);
	}
};
