import { useContext } from 'react';
import type { StoreApi } from 'zustand';
import { useStore } from 'zustand';
import { devtools } from 'zustand/middleware';

import { fetchSaleTimes } from 'Services/api/sale';
import { createGenericStoreWithDevtools } from 'Stores/store';
import { SaleTimesContext } from 'Stores/StoresContext/StoresContext';
import type { SaleTimes } from 'Types/saleTimes';
import { isDev } from 'Utilities/helpers';

import type { SaleTimesStore } from './SaleTimeStore.types';

const createSaleTimesStore = (initialSaleTimes: SaleTimes) =>
	devtools<SaleTimesStore>(
		(set) => ({
			refetchSaleTimes: async () => {
				const saleTimes = await fetchSaleTimes();
				set({ saleTimes }, false, { saleTimes, type: 'refetchSaleTimes' });
				return saleTimes;
			},
			saleTimes: initialSaleTimes,
			setSaleTimes: (saleTimes: SaleTimes) => {
				set({ saleTimes }, false, { saleTimes, type: 'setSaleTimes' });
			},
		}),
		{ enabled: isDev, name: 'ListStore' },
	);

export const createSaleTimesStoreWithInitial = (saleTimes: SaleTimes): StoreApi<SaleTimesStore> =>
	createGenericStoreWithDevtools(createSaleTimesStore(saleTimes));

export const useSaleTimesStore = <T>(selector: (state: SaleTimesStore) => T, equalityFn?: () => boolean): T => {
	const store = useContext(SaleTimesContext);
	if (!store) {
		throw new Error('Missing SaleTimesContext.Provider in the tree');
	}

	return useStore(store, selector, equalityFn);
};

export const useSaleTimesFromStore = (): SaleTimesStore['saleTimes'] => useSaleTimesStore((store) => store.saleTimes);
export const useRefetchSaleTimes = (): SaleTimesStore['refetchSaleTimes'] =>
	useSaleTimesStore((store) => store.refetchSaleTimes);
export const useSetSaleTimes = (): SaleTimesStore['setSaleTimes'] => useSaleTimesStore((store) => store.setSaleTimes);

export default useSaleTimesStore;
