export default [
	{
		id: 1,
		name: 'Rover',
		slug: 'rover',
		total: 1,
	},
	{
		id: 2,
		name: 'Subaru',
		slug: 'subaru',
		total: 1,
	},
	{
		id: 3,
		name: 'Hyundai',
		slug: 'hyundai',
		total: 33,
	},
	{
		id: 4,
		name: 'Skoda',
		slug: 'skoda',
		total: 15,
	},
	{
		id: 5,
		name: 'Dacia',
		slug: 'dacia',
		total: 5,
	},
	{
		id: 6,
		name: 'SEAT',
		slug: 'seat',
		total: 28,
	},
	{
		id: 7,
		name: 'Audi',
		slug: 'audi',
		total: 275,
	},
	{
		id: 8,
		name: 'Jaguar',
		slug: 'jaguar',
		total: 36,
	},
	{
		id: 9,
		name: 'Peugeot',
		slug: 'peugeot',
		total: 7,
	},
	{
		id: 10,
		name: 'Honda',
		slug: 'honda',
		total: 18,
	},
	{
		id: 11,
		name: 'Rolls-Royce',
		slug: 'rolls-royce',
		total: 13,
	},
	{
		id: 12,
		name: 'Fiat',
		slug: 'fiat',
		total: 12,
	},
	{
		id: 13,
		name: 'Volvo',
		slug: 'volvo',
		total: 69,
	},
	{
		id: 14,
		name: 'Nissan',
		slug: 'nissan',
		total: 12,
	},
	{
		id: 15,
		name: 'Ford',
		slug: 'ford',
		total: 75,
	},
	{
		id: 16,
		name: 'Polestar',
		slug: 'polestar',
		total: 1,
	},
	{
		id: 17,
		name: 'Volkswagen',
		slug: 'volkswagen',
		total: 130,
	},
	{
		id: 18,
		name: 'Renault',
		slug: 'renault',
		total: 14,
	},
	{
		id: 19,
		name: 'Vauxhall',
		slug: 'vauxhall',
		total: 34,
	},
	{
		id: 20,
		name: 'BMW',
		slug: 'bmw',
		total: 192,
	},
	{
		id: 21,
		name: 'Citroen',
		slug: 'citroen',
		total: 13,
	},
	{
		id: 22,
		name: 'Ferrari',
		slug: 'ferrari',
		total: 3,
	},
	{
		id: 23,
		name: 'Dodge',
		slug: 'dodge',
		total: 1,
	},
	{
		id: 24,
		name: 'Infiniti',
		slug: 'infiniti',
		total: 4,
	},
	{
		id: 25,
		name: 'Abarth',
		slug: 'abarth',
		total: 7,
	},
	{
		id: 26,
		name: 'Lotus',
		slug: 'lotus',
		total: 2,
	},
	{
		id: 27,
		name: 'Porsche',
		slug: 'porsche',
		total: 31,
	},
	{
		id: 28,
		name: 'Maserati',
		slug: 'maserati',
		total: 4,
	},
	{
		id: 29,
		name: 'Suzuki',
		slug: 'suzuki',
		total: 29,
	},
	{
		id: 30,
		name: 'Smart',
		slug: 'smart',
		total: 12,
	},
	{
		id: 31,
		name: 'Tesla',
		slug: 'tesla',
		total: 6,
	},
	{
		id: 32,
		name: 'DS',
		slug: 'ds',
		total: 1,
	},
	{
		id: 33,
		name: 'Lamborghini',
		slug: 'lamborghini',
		total: 6,
	},
	{
		id: 34,
		name: 'Bentley',
		slug: 'bentley',
		total: 16,
	},
	{
		id: 35,
		name: 'Lexus',
		slug: 'lexus',
		total: 17,
	},
	{
		id: 36,
		name: 'MG',
		slug: 'mg',
		total: 4,
	},
	{
		id: 37,
		name: 'Jeep',
		slug: 'jeep',
		total: 17,
	},
	{
		id: 38,
		name: 'Mazda',
		slug: 'mazda',
		total: 10,
	},
	{
		id: 39,
		name: 'Mitsubishi',
		slug: 'mitsubishi',
		total: 2,
	},
	{
		id: 40,
		name: 'Aston Martin',
		slug: 'aston-martin',
		total: 18,
	},
	{
		id: 41,
		name: 'Mercedes',
		slug: 'mercedes',
		total: 126,
	},
	{
		id: 42,
		name: 'Land Rover',
		slug: 'land-rover',
		total: 101,
	},
	{
		id: 43,
		name: 'Kia',
		slug: 'kia',
		total: 44,
	},
	{
		id: 44,
		name: 'Toyota',
		slug: 'toyota',
		total: 34,
	},
	{
		id: 45,
		name: 'MINI',
		slug: 'mini',
		total: 52,
	},
	{
		id: 46,
		name: 'Other',
		slug: 'other',
		total: 10,
	},
];
