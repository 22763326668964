import { getSnowplowEventPayload, logAnalyticsEvent } from 'Services/analytics/analytics';
import {
	ANALYTICS_CATEGORIES,
	SNOWPLOW_BACKEND_ENTITIES,
	SNOWPLOW_BACKEND_SERVICES,
	SNOWPLOW_EVENTS,
} from 'Services/analytics/analytics.const';
import type { SnowplowBackendTriggerEventSchema } from 'Services/analytics/analytics.types';
import { AccountSlugs } from 'Types/account';

const { ACCOUNT } = ANALYTICS_CATEGORIES;

const { BIDDING_AND_PURCHASE_HISTORY } = ACCOUNT;

type TriggerPayloadWithId = { id: number | string };

export const triggerActiveBidsSidebarClickedEvent = (): void => {
	const eventPayload = getSnowplowEventPayload({
		category: BIDDING_AND_PURCHASE_HISTORY,
		name: 'dlr_bno_active_side_clicked',
		schemaName: SNOWPLOW_EVENTS.CLICK.SCHEMA,
		version: SNOWPLOW_EVENTS.CLICK.VERSION,
	});

	logAnalyticsEvent({ eventPayload });
};

export const triggerUnderOfferBidsSidebarClickedEvent = (): void => {
	const eventPayload = getSnowplowEventPayload({
		category: BIDDING_AND_PURCHASE_HISTORY,
		name: 'dlr_bno_under_offer_side_clicked',
		schemaName: SNOWPLOW_EVENTS.CLICK.SCHEMA,
		version: SNOWPLOW_EVENTS.CLICK.VERSION,
	});

	logAnalyticsEvent({ eventPayload });
};

export const triggerDidntWinBidsSidebarClickedEvent = (): void => {
	const eventPayload = getSnowplowEventPayload({
		category: BIDDING_AND_PURCHASE_HISTORY,
		name: 'dlr_bno_didnt_win_side_clicked',
		schemaName: SNOWPLOW_EVENTS.CLICK.SCHEMA,
		version: SNOWPLOW_EVENTS.CLICK.VERSION,
	});

	logAnalyticsEvent({ eventPayload });
};

export const triggerCancelledPurchaseSidebarClickedEvent = (): void => {
	const eventPayload = getSnowplowEventPayload({
		category: BIDDING_AND_PURCHASE_HISTORY,
		name: 'dlr_purchases_cancelled_side_clicked',
		schemaName: SNOWPLOW_EVENTS.CLICK.SCHEMA,
		version: SNOWPLOW_EVENTS.CLICK.VERSION,
	});

	logAnalyticsEvent({ eventPayload });
};

export const triggerCompletePurchaseSidebarClickedEvent = (): void => {
	const eventPayload = getSnowplowEventPayload({
		category: BIDDING_AND_PURCHASE_HISTORY,
		name: 'dlr_purchases_complete_side_clicked',
		schemaName: SNOWPLOW_EVENTS.CLICK.SCHEMA,
		version: SNOWPLOW_EVENTS.CLICK.VERSION,
	});

	logAnalyticsEvent({ eventPayload });
};

export const triggerCompleteMoreMenuClickedEvent = ({ id }: TriggerPayloadWithId): void => {
	const eventPayload = getSnowplowEventPayload({
		category: ACCOUNT[AccountSlugs.COMPLETE],
		customData: { label: String(id) },
		name: 'dlr_purchases_complete_more_menu_clicked',
		schemaName: SNOWPLOW_EVENTS.CLICK.SCHEMA,
		version: SNOWPLOW_EVENTS.CLICK.VERSION,
	});

	logAnalyticsEvent({ eventPayload });
};
export const triggerCompletePurchasePaymentMenuClickedEvent = ({ id }: TriggerPayloadWithId): void => {
	const eventPayload = getSnowplowEventPayload({
		category: ACCOUNT[AccountSlugs.COMPLETE],
		customData: { label: String(id) },
		name: 'dlr_purchases_complete_vehicle_payments_clicked',
		schemaName: SNOWPLOW_EVENTS.CLICK.SCHEMA,
		version: SNOWPLOW_EVENTS.CLICK.VERSION,
	});

	logAnalyticsEvent({ eventPayload });
};

export const triggerCompletePurchasesSellerDetailsMenuClickedEvent = ({ id }: TriggerPayloadWithId): void => {
	const eventPayload = getSnowplowEventPayload({
		category: ACCOUNT[AccountSlugs.COMPLETE],
		customData: { label: String(id) },
		name: 'dlr_purchases_complete_seller_details_clicked',
		schemaName: SNOWPLOW_EVENTS.CLICK.SCHEMA,
		version: SNOWPLOW_EVENTS.CLICK.VERSION,
	});

	logAnalyticsEvent({ eventPayload });
};

export const triggerCompletePurchasesCancellationMenuClickedEvent = ({ id }: TriggerPayloadWithId): void => {
	const eventPayload = getSnowplowEventPayload({
		category: ACCOUNT[AccountSlugs.COMPLETE],
		customData: { label: String(id) },
		name: 'dlr_purchases_complete_cancellation_clicked',
		schemaName: SNOWPLOW_EVENTS.CLICK.SCHEMA,
		version: SNOWPLOW_EVENTS.CLICK.VERSION,
	});

	logAnalyticsEvent({ eventPayload });
};

export const triggerCompletePurchasesDeliveryDetailsMenuClickedEvent = ({ id }: TriggerPayloadWithId): void => {
	const eventPayload = getSnowplowEventPayload({
		category: ACCOUNT[AccountSlugs.COMPLETE],
		customData: { label: String(id) },
		name: 'dlr_purchases_complete_delivery_details_clicked',
		schemaName: SNOWPLOW_EVENTS.CLICK.SCHEMA,
		version: SNOWPLOW_EVENTS.CLICK.VERSION,
	});

	logAnalyticsEvent({ eventPayload });
};

export const triggerCompletePurchasesStatusChipClickedEvent = ({ status }: { status: string }): void => {
	const eventPayload = getSnowplowEventPayload({
		category: ACCOUNT[AccountSlugs.COMPLETE],
		customData: { label: status },
		name: 'dlr_purchases_complete_status_filter_clicked',
		schemaName: SNOWPLOW_EVENTS.CLICK.SCHEMA,
		version: SNOWPLOW_EVENTS.CLICK.VERSION,
	});

	logAnalyticsEvent({ eventPayload });
};

export const triggerCompletePurchasesDownloadCSVClickedEvent = (): void => {
	const eventPayload = getSnowplowEventPayload({
		category: ACCOUNT[AccountSlugs.COMPLETE],
		name: 'dlr_purchases_complete_download_clicked',
		schemaName: SNOWPLOW_EVENTS.CLICK.SCHEMA,
		version: SNOWPLOW_EVENTS.CLICK.VERSION,
	});

	logAnalyticsEvent({ eventPayload });
};

export const triggerCompletePurchasesDownloadCSVHoveredEvent = (): void => {
	const eventPayload = getSnowplowEventPayload({
		category: ACCOUNT[AccountSlugs.COMPLETE],
		name: 'dlr_purchases_complete_download_hovered',
		schemaName: SNOWPLOW_EVENTS.HOVER.SCHEMA,
		version: SNOWPLOW_EVENTS.HOVER.VERSION,
	});

	logAnalyticsEvent({ eventPayload });
};

export const triggerCompletePurchasesDownloadCSVAbortedEvent = (): void => {
	const eventPayload = getSnowplowEventPayload<SnowplowBackendTriggerEventSchema>({
		action: 'dlr_purchases_complete_download_aborted',
		backend_entity: SNOWPLOW_BACKEND_ENTITIES.DOWNLOAD,
		backend_service: SNOWPLOW_BACKEND_SERVICES.PURCHASES_COMPLETE,
		schemaName: SNOWPLOW_EVENTS.BACKEND_TRIGGER.SCHEMA,
		success: true,
		version: SNOWPLOW_EVENTS.BACKEND_TRIGGER.VERSION,
	});

	logAnalyticsEvent({ eventPayload });
};

export const triggerCompletePurchasesDownloadCSVFailedEvent = (): void => {
	const eventPayload = getSnowplowEventPayload<SnowplowBackendTriggerEventSchema>({
		action: 'dlr_purchases_complete_download_failed',
		backend_entity: SNOWPLOW_BACKEND_ENTITIES.DOWNLOAD,
		backend_service: SNOWPLOW_BACKEND_SERVICES.PURCHASES_COMPLETE,
		schemaName: SNOWPLOW_EVENTS.BACKEND_TRIGGER.SCHEMA,
		success: true,
		version: SNOWPLOW_EVENTS.BACKEND_TRIGGER.VERSION,
	});

	logAnalyticsEvent({ eventPayload });
};

export const triggerCompletePurchasesDownloadCSVSuccessEvent = (): void => {
	const eventPayload = getSnowplowEventPayload<SnowplowBackendTriggerEventSchema>({
		action: 'dlr_purchases_complete_download_success',
		backend_entity: SNOWPLOW_BACKEND_ENTITIES.DOWNLOAD,
		backend_service: SNOWPLOW_BACKEND_SERVICES.PURCHASES_COMPLETE,
		schemaName: SNOWPLOW_EVENTS.BACKEND_TRIGGER.SCHEMA,
		success: true,
		version: SNOWPLOW_EVENTS.BACKEND_TRIGGER.VERSION,
	});

	logAnalyticsEvent({ eventPayload });
};

export const triggerCompletePurchasesPricePopoverSuccessEvent = (): void => {
	const eventPayload = getSnowplowEventPayload({
		category: ACCOUNT[AccountSlugs.COMPLETE],
		name: 'dlr_purchases_complete_price_popover_clicked',
		schemaName: SNOWPLOW_EVENTS.CLICK.SCHEMA,
		version: SNOWPLOW_EVENTS.CLICK.VERSION,
	});

	logAnalyticsEvent({ eventPayload });
};
