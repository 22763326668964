import type { AxiosError } from 'axios';

export enum AxiosErrorTypes {
	request = 'The request was made but no response was received',
	response = 'The request was made and the server responded with a status code that falls out of the range of 2xx',
	setup = 'Something happened in setting up the request that triggered an Error',
}

export const getAxiosErrorType = ({ request, response }: Pick<AxiosError, 'response' | 'request'>): AxiosErrorTypes => {
	if (response) {
		return AxiosErrorTypes.response;
	}
	if (request) {
		return AxiosErrorTypes.request;
	}
	return AxiosErrorTypes.setup;
};
