import type { GAEventPayload } from 'Services/analytics/analytics.types';

import { getSnowplowEventPayload, logAnalyticsEvents } from '../../analytics';
import { ANALYTICS_CATEGORIES, ANALYTICS_PROVIDERS, SNOWPLOW_EVENTS } from '../../analytics.const';

const { GOOGLE_ANALYTICS, SNOWPLOW } = ANALYTICS_PROVIDERS;

export const triggerDistanceTooltipHoverEvent = (): void => {
	const snowplowEventPayload = getSnowplowEventPayload({
		category: ANALYTICS_CATEGORIES.VEHICLE_DETAILS,
		name: 'dlr_distance_tooltip_hover',
		schemaName: SNOWPLOW_EVENTS.HOVER.SCHEMA,
		version: SNOWPLOW_EVENTS.HOVER.VERSION,
	});

	const googleAnalyticsEventPayload: GAEventPayload = {
		action: 'Distance tooltip hover',
		category: ANALYTICS_CATEGORIES.VEHICLE_DETAILS,
		isLegacyEvent: false,
		label: null,
	};

	logAnalyticsEvents([
		{
			eventPayload: snowplowEventPayload,
			provider: SNOWPLOW,
		},
		{
			eventPayload: googleAnalyticsEventPayload,
			provider: GOOGLE_ANALYTICS,
		},
	]);
};
