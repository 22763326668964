import dayjs from 'dayjs';

import type { SaleTimes } from 'Types/saleTimes';
import { isSaleToday } from 'Utilities/helpers/isSaleLaterToday';
import { API_ROUTES } from 'Utilities/routes';

import { isEmptyObject } from '../../utilities/helpers';
import axios from '../axios';

export const defaultSaleTimes: SaleTimes = {
	hasReceivedSaleTimes: true,
	isSaleActive: false,
	isSaleLaterToday: false,
	millisecondsRemaining: 0,
	nextSaleStartsAt: null,
	saleClosingHour: '',
	saleClosingMinute: '',
	todaysSaleEndsAt: null,
	todaysSaleStartedAt: null,
};

export const fetchSaleTimes = async (): Promise<SaleTimes> => {
	const { data } = await axios.get<SaleTimes>(API_ROUTES.SALE);

	if (data) {
		const isSaleLaterToday = isSaleToday(data?.nextSaleStartsAt);

		return {
			hasReceivedSaleTimes: true,
			isSaleActive: data.isSaleActive,
			isSaleLaterToday,
			millisecondsRemaining: data.millisecondsRemaining,
			nextSaleStartsAt: data.nextSaleStartsAt,
			saleClosingHour: data.saleClosingHour,
			saleClosingMinute: data.saleClosingMinute,
			todaysSaleEndsAt: data.todaysSaleEndsAt,
			todaysSaleStartedAt: data.todaysSaleStartedAt,
		};
	}

	return defaultSaleTimes;
};

export const getSaleTime = async (saleTimes: SaleTimes = {} as SaleTimes): Promise<SaleTimes> => {
	const currentSaleEndTime = dayjs(saleTimes?.todaysSaleEndsAt);
	const nextSaleStartTime = dayjs(saleTimes?.nextSaleStartsAt);
	const hasSaleTimes = !isEmptyObject(saleTimes);
	const isLiveSaleStartTransitionTime = dayjs().isSameOrAfter(nextSaleStartTime);
	const isLiveSaleEndTransitionTime = saleTimes.isSaleActive && dayjs().isSameOrAfter(currentSaleEndTime);
	const isSaleTransitionTime = isLiveSaleStartTransitionTime || isLiveSaleEndTransitionTime;

	// If we already have bidding times, we only want to refetch them when a sale transition is happening
	if (hasSaleTimes && !isSaleTransitionTime) {
		return { ...saleTimes };
	}

	return fetchSaleTimes();
};

export default {
	getSaleTime,
};
