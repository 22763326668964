import isUndefined from 'lodash/isUndefined';

import type { MakesStore } from 'Stores/MakesStore/MakesStore.types';
import type { ModelsStore } from 'Stores/ModelsStore/ModelsStore.types';
import type { MakeCount, Makes } from 'Types/make';
import type { ModelCount, Models } from 'Types/model';

export interface Entity {
	id: number;
	total: number;
}

export interface GetTotalCountsProps<T> {
	counts: MakeCount | ModelCount;
	entities: (T & Entity)[];
}

export const getTotalCounts = <T>({ counts, entities }: GetTotalCountsProps<T>): (T & Entity)[] => {
	const entityIdsWithCounts = Object.keys(counts).map((key) => parseInt(key));

	return entities.map((entity) => {
		if (entityIdsWithCounts.includes(entity?.id)) {
			entity.total = counts?.[entity?.id] || 0;
		} else {
			entity.total = 0;
		}
		return entity;
	});
};

export const getMakesDictionaries = (makes: Makes = []): Pick<MakesStore, 'makesById' | 'makesBySlug'> =>
	makes.reduce(
		(acc, make) => {
			acc.makesById = acc.makesById.set(parseInt(make?.id?.toString?.()), make);
			acc.makesBySlug = acc.makesBySlug.set(make?.slug, make);
			return acc;
		},
		{ makesById: new Map(), makesBySlug: new Map() },
	);

export const getModelsDictionaries = (models: Models = []): Pick<ModelsStore, 'modelsById' | 'modelsByMakeId'> =>
	models.reduce(
		(acc, model) => {
			acc.modelsById = acc.modelsById.set(parseInt(model?.id?.toString?.()), model);

			const modelsByMake = acc.modelsByMakeId.get(model.makeId);
			if (modelsByMake && modelsByMake.length) {
				acc.modelsByMakeId = acc.modelsByMakeId.set(model?.makeId, [...modelsByMake, model]);
			} else {
				acc.modelsByMakeId = acc.modelsByMakeId.set(model?.makeId, [model]);
			}

			return acc;
		},
		{
			modelsById: new Map(),
			modelsByMakeId: new Map(),
		},
	);

type GetSelectedMakesWithoutSelectedModelsProps = { makes: Makes; models: Models };

export const getSelectedMakesWithoutSelectedModels = ({
	makes = [],
	models = [],
}: GetSelectedMakesWithoutSelectedModelsProps): string[] =>
	makes
		.filter((make) => isUndefined(models?.find((model) => model?.makeId === parseInt(make?.id?.toString?.()))))
		.map((make) => make?.slug);
