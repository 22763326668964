import pick from 'lodash/pick';
import type { IStringifyOptions } from 'qs';

import { LIST_TYPES } from 'Utilities/consts';

import { allowedVehicleStatesByFilter } from '../utilities/vehicleState';

export const RESULTS_PER_PAGE = 36;
export const TEST_VEHICLE_ID = 30469;
export const QUERY_STRING_OPTIONS: IStringifyOptions = { allowDots: true, arrayFormat: 'repeat' };

export const ALLOWED_PROXY_HEADERS = ['x-mway-providers-versions', 'x-mway-use-search'];
export const MAKE_MODEL_COUNT_STATES = [LIST_TYPES.auction, LIST_TYPES.buyItNow, LIST_TYPES.shortlist];

export const MOTORWAY_PROVIDERS = {
	MOTORWAY_BIDDING: 'motorway-bidding',
	MW_CUSTOMER_SUPPORT: 'motorway-customer-support',
	MW_DEALER_CORE: 'mw-dealer-core',
	MW_DEALER_OTP: 'mw-dealer-otp',
	MW_DEALERSHIP_COMPOSER: 'mw-dealership-composer',
	MW_DEALERSHIP_PLATFORM: 'motorway-dealership-platform',
	MW_HTML_FOR_PDF: 'mw-html-for-pdf',
	MW_PAYMENTS: 'mw-payments',
	MW_PDF: 'mw-pdf',
	MW_SERVICE_HISTORY: 'mw-service-history',
	MW_TRANSACTION_CORE: 'mw-transaction-core',
	MW_VEHICLE_CORE: 'mw-vehicle-core',
};

export const DEFAULT_MODELS_QUERY = 'skipModelsWithoutVehicles=true&displayAllModels=false';

export const ALLOWED_STATES_FOR_MAKE_MODEL_COUNT = pick(allowedVehicleStatesByFilter, MAKE_MODEL_COUNT_STATES);

export const FEATURE_FLAG_SCOPES = { dealer: 'dealer', dealership: 'dealership' } as const;

// LD feature flags will not work for unauthenticated users. The endpoint is currently restricted.
export const BE_TO_FE_FEATURE_FLAGS_MAP = {
	ACCOUNT_REPORTING: { feToggleName: 'accountReporting', scope: FEATURE_FLAG_SCOPES.dealership },
	BID_COUNT_SORT_ORDER_DWA: { feToggleName: 'bidCountsSort', scope: FEATURE_FLAG_SCOPES.dealer },
	ENHANCED_SERVICE_HISTORY: { feToggleName: 'enhancedServiceHistory', scope: FEATURE_FLAG_SCOPES.dealership },
	FORCE_REFRESH_ON_OUT_OF_SYNC_BUILD: {
		feToggleName: 'forceRefreshOnOutOfSyncBuild',
		scope: FEATURE_FLAG_SCOPES.dealer,
	},
	INSIGHTS: { feToggleName: 'insights', scope: FEATURE_FLAG_SCOPES.dealership },
	LOG_VDP_SCHEMA_DIFFERENCE: { feToggleName: 'logVdpSchemaDifference', scope: FEATURE_FLAG_SCOPES.dealer },
	MULTI_SITE_DEALER: { feToggleName: 'multiSiteDealer', scope: FEATURE_FLAG_SCOPES.dealer },
	PEN_TESTING_BIDDING: { feToggleName: 'penTestingBidding', scope: FEATURE_FLAG_SCOPES.dealership },
	SIMILAR_VEHICLES_MAKE_MODEL: { feToggleName: 'similarVehiclesMakeModel', scope: FEATURE_FLAG_SCOPES.dealer },
	SIMILAR_VEHICLES_V2: { feToggleName: 'similarVehiclesV2', scope: FEATURE_FLAG_SCOPES.dealer },
	TRANSPORT_PROVIDER_USER: { feToggleName: 'estimatedDeliveryCosts', scope: FEATURE_FLAG_SCOPES.dealership },
	VEHICLE_DETAILS_REDESIGN: { feToggleName: 'vehicleDetailsRedesign', scope: FEATURE_FLAG_SCOPES.dealership },
} as const;

export const INVERSE_FEATURE_TOGGLES = ['estimatedDeliveryCosts'];

export const SALES_RULES = [
	'isSaleActive',
	'todaysSaleStartedAt',
	'todaysSaleEndsAt',
	'nextSaleStartsAt',
	'saleClosingHour',
	'saleClosingMinute',
];
