/* eslint-disable @typescript-eslint/no-explicit-any */
import { getSnowplowEventPayload } from 'Services/analytics/analytics';
import { ANALYTICS_CATEGORIES, ANALYTICS_PROVIDERS } from 'Services/analytics/analytics.const';
import type { SnowplowEventPayload } from 'Services/analytics/analytics.types';

import type { KycAnalyticsEvent } from './types';

const { KYC } = ANALYTICS_CATEGORIES;
const { SNOWPLOW } = ANALYTICS_PROVIDERS;

export const createSnowplowEvents = (
	events: KycAnalyticsEvent[],
): {
	eventPayload: SnowplowEventPayload;
	provider: typeof SNOWPLOW;
}[] =>
	events.map((evt: KycAnalyticsEvent) => {
		return {
			eventPayload: getSnowplowEventPayload({
				category: KYC,
				customData: { label: evt?.label, ...evt?.customData },
				name: `${evt?.type}_${evt?.name}`,
				schemaName: evt?.schema,
				version: evt?.version,
			}),
			provider: SNOWPLOW,
		};
	});
