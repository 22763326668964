import { isNull } from 'lodash';
import snakeCase from 'lodash/snakeCase';

import type { VEHICLE_CLASSES } from 'Components/VehicleList/VehicleListFilters.consts';
import { formatText, toTitleCase } from 'Utilities/formatters';
import { capitaliseSentence, pascalCase, splitCamelCase } from 'Utilities/helpers';

import { getSnowplowEventPayload, logAnalyticsEvent } from '../analytics';
import { ANALYTICS_CATEGORIES, ANALYTICS_PROVIDERS, SNOWPLOW_EVENTS } from '../analytics.const';

const {
	MARKETPLACE: { FILTERING },
} = ANALYTICS_CATEGORIES;
const { GOOGLE_ANALYTICS } = ANALYTICS_PROVIDERS;

interface TriggerSliderFilterEventProps {
	fieldType: string;
	nameOfField: string;
	value: Nullable<number | boolean>;
}

export const triggerSliderFilterEvent = ({ fieldType, nameOfField, value }: TriggerSliderFilterEventProps): void => {
	const formattedName = snakeCase(nameOfField);
	const formattedType = fieldType.toLowerCase();
	const snowplowEventName = `dlr_mrktplace_filter_${formattedName}_${formattedType}_clicked`;

	const eventPayload = getSnowplowEventPayload({
		category: FILTERING,
		customData: {
			label: isNull(value) ? value : `${value}`,
		},
		name: snowplowEventName,
		schemaName: SNOWPLOW_EVENTS.CLICK.SCHEMA,
		version: SNOWPLOW_EVENTS.CLICK.VERSION,
	});

	logAnalyticsEvent({ eventPayload });
	logAnalyticsEvent({
		eventPayload: {
			action: `${toTitleCase(nameOfField)} (${fieldType}) set`,
			category: FILTERING,
			isLegacyEvent: false,
			label: value,
		},
		provider: GOOGLE_ANALYTICS,
	});
};

export const triggerBuyingFormatSelectedEvent = (buyingFormat: string): void => {
	const formattedName = snakeCase(buyingFormat);

	const eventPayload = getSnowplowEventPayload({
		category: FILTERING,
		name: `dlr_${formattedName}_clicked`,
		schemaName: SNOWPLOW_EVENTS.CLICK.SCHEMA,
		version: SNOWPLOW_EVENTS.CLICK.VERSION,
	});

	logAnalyticsEvent({ eventPayload });
	logAnalyticsEvent({
		eventPayload: {
			action: `${splitCamelCase(buyingFormat).toLowerCase()} selected`,
			category: FILTERING,
			isLegacyEvent: false,
			label: '',
		},
		provider: GOOGLE_ANALYTICS,
	});
};

export const triggerClearAllFiltersButtonClickedEvent = (): void => {
	const eventPayload = getSnowplowEventPayload({
		category: FILTERING,
		name: 'dlr_mrktplace_filter_clear_all_clicked',
		schemaName: SNOWPLOW_EVENTS.CLICK.SCHEMA,
		version: SNOWPLOW_EVENTS.CLICK.VERSION,
	});

	logAnalyticsEvent({ eventPayload });
	logAnalyticsEvent({
		eventPayload: {
			action: 'Clear all filters button clicked',
			category: FILTERING,
			isLegacyEvent: false,
			label: '',
		},
		provider: GOOGLE_ANALYTICS,
	});
};

export const triggerCloseButtonClickedEvent = (): void => {
	const eventPayload = getSnowplowEventPayload({
		category: FILTERING,
		name: 'dlr_mrktplace_filter_close_clicked',
		schemaName: SNOWPLOW_EVENTS.CLICK.SCHEMA,
		version: SNOWPLOW_EVENTS.CLICK.VERSION,
	});

	logAnalyticsEvent({ eventPayload });
	logAnalyticsEvent({
		eventPayload: {
			action: 'Close button clicked',
			category: FILTERING,
			isLegacyEvent: false,
			label: '',
		},
		provider: GOOGLE_ANALYTICS,
	});
};

export const triggerCollectionToggleSelectedEvent = (nextIsAvailableForCollection: boolean): void => {
	const label = nextIsAvailableForCollection ? 'Available Now' : 'All';

	const eventPayload = getSnowplowEventPayload({
		category: FILTERING,
		customData: { label },
		name: 'dlr_mrktplace_filter_collection_toggle_clicked',
		schemaName: SNOWPLOW_EVENTS.CLICK.SCHEMA,
		version: SNOWPLOW_EVENTS.CLICK.VERSION,
	});

	logAnalyticsEvent({ eventPayload });
	logAnalyticsEvent({
		eventPayload: {
			action: 'Collection toggle selected',
			category: FILTERING,
			isLegacyEvent: false,
			label,
		},
		provider: GOOGLE_ANALYTICS,
	});
};

interface TriggerCheckboxGroupFilterSetEventProps {
	clickedOption: string;
	section: string;
	value: boolean;
}

export const triggerCheckboxGroupFilterSetEvent = ({
	clickedOption,
	section,
	value,
}: TriggerCheckboxGroupFilterSetEventProps): void => {
	const label = `${capitaliseSentence(clickedOption)} ${value ? 'selected' : 'unselected'}`;

	const eventPayload = getSnowplowEventPayload({
		category: FILTERING,
		customData: { label },
		name: `dlr_mrktplace_filter_${snakeCase(section)}_clicked`,
		schemaName: SNOWPLOW_EVENTS.CLICK.SCHEMA,
		version: SNOWPLOW_EVENTS.CLICK.VERSION,
	});

	logAnalyticsEvent({ eventPayload });
	logAnalyticsEvent({
		eventPayload: {
			action: `${formatText(splitCamelCase(section))} type set`,
			category: FILTERING,
			isLegacyEvent: false,
			label,
		},
		provider: GOOGLE_ANALYTICS,
	});
};

export const triggerFilterButtonClickedEvent = (): void => {
	const eventPayload = getSnowplowEventPayload({
		category: FILTERING,
		name: 'dlr_mrktplace_filter_clicked',
		schemaName: SNOWPLOW_EVENTS.CLICK.SCHEMA,
		version: SNOWPLOW_EVENTS.CLICK.VERSION,
	});

	logAnalyticsEvent({ eventPayload });
	logAnalyticsEvent({
		eventPayload: {
			action: 'Filter button clicked',
			category: FILTERING,
			isLegacyEvent: false,
			label: '',
		},
		provider: GOOGLE_ANALYTICS,
	});
};

interface TriggerMakeSelectedEventProps {
	isSelected: boolean;
	text: string;
}

export const triggerMakeSelectedEvent = ({ isSelected, text }: TriggerMakeSelectedEventProps): void => {
	const action = isSelected ? 'selected' : 'de-selected';
	const eventPayload = getSnowplowEventPayload({
		category: FILTERING,
		customData: { label: text },
		name: `dlr_mrktplace_filter_make_${action.replace('-', '')}_clicked`,
		schemaName: SNOWPLOW_EVENTS.CLICK.SCHEMA,
		version: SNOWPLOW_EVENTS.CLICK.VERSION,
	});

	logAnalyticsEvent({ eventPayload });
	logAnalyticsEvent({
		eventPayload: {
			action: `Make ${action}`,
			category: FILTERING,
			isLegacyEvent: false,
			label: text,
		},
		provider: GOOGLE_ANALYTICS,
	});
};

export const triggerMakeDropdownExpandedEvent = (): void => {
	const eventPayload = getSnowplowEventPayload({
		category: FILTERING,
		name: 'dlr_mrktplace_filter_make_dropdown_clicked',
		schemaName: SNOWPLOW_EVENTS.CLICK.SCHEMA,
		version: SNOWPLOW_EVENTS.CLICK.VERSION,
	});

	logAnalyticsEvent({ eventPayload });
	logAnalyticsEvent({
		eventPayload: {
			action: 'Make dropdown expanded',
			category: FILTERING,
			isLegacyEvent: false,
			label: '',
		},
		provider: GOOGLE_ANALYTICS,
	});
};

interface TriggerMakeSearchInputEventProps {
	searchValue: string;
}

export const triggerMakeSearchInputEvent = ({ searchValue }: TriggerMakeSearchInputEventProps): void => {
	const eventPayload = getSnowplowEventPayload({
		category: FILTERING,
		customData: { label: searchValue },
		name: 'dlr_mrktplace_filter_make_search_input',
		schemaName: SNOWPLOW_EVENTS.CLICK.SCHEMA,
		version: SNOWPLOW_EVENTS.CLICK.VERSION,
	});

	logAnalyticsEvent({ eventPayload });
	logAnalyticsEvent({
		eventPayload: {
			action: 'Make search input',
			category: FILTERING,
			isLegacyEvent: false,
			label: searchValue,
		},
		provider: GOOGLE_ANALYTICS,
	});
};

export const triggerModelSelectedEvent = ({ isSelected, text }: TriggerMakeSelectedEventProps): void => {
	const action = isSelected ? 'selected' : 'de-selected';
	const eventPayload = getSnowplowEventPayload({
		category: FILTERING,
		customData: { label: text },
		name: `dlr_mrktplace_filter_model_${action.replace('-', '')}_clicked`,
		schemaName: SNOWPLOW_EVENTS.CLICK.SCHEMA,
		version: SNOWPLOW_EVENTS.CLICK.VERSION,
	});

	logAnalyticsEvent({ eventPayload });
	logAnalyticsEvent({
		eventPayload: {
			action: `Model ${action}`,
			category: FILTERING,
			isLegacyEvent: false,
			label: text,
		},
		provider: GOOGLE_ANALYTICS,
	});
};

export const triggerModelDropdownExpandedEvent = (): void => {
	const eventPayload = getSnowplowEventPayload({
		category: FILTERING,
		name: 'dlr_mrktplace_filter_model_dropdown_clicked',
		schemaName: SNOWPLOW_EVENTS.CLICK.SCHEMA,
		version: SNOWPLOW_EVENTS.CLICK.VERSION,
	});

	logAnalyticsEvent({ eventPayload });
	logAnalyticsEvent({
		eventPayload: {
			action: 'Model dropdown expanded',
			category: FILTERING,
			isLegacyEvent: false,
			label: '',
		},
		provider: GOOGLE_ANALYTICS,
	});
};

export const triggerModelSearchInputEvent = ({ searchValue }: TriggerMakeSearchInputEventProps): void => {
	const eventPayload = getSnowplowEventPayload({
		category: FILTERING,
		customData: { label: searchValue },
		name: 'dlr_mrktplace_filter_model_search_input',
		schemaName: SNOWPLOW_EVENTS.CLICK.SCHEMA,
		version: SNOWPLOW_EVENTS.CLICK.VERSION,
	});

	logAnalyticsEvent({ eventPayload });
	logAnalyticsEvent({
		eventPayload: {
			action: 'Model search input',
			category: FILTERING,
			isLegacyEvent: false,
			label: searchValue,
		},
		provider: GOOGLE_ANALYTICS,
	});
};

interface TriggerVehicleClassSelectedEventProps {
	isSelected: boolean;
	vehicleClassKey: keyof typeof VEHICLE_CLASSES;
}

export const triggerVehicleClassSelectedEvent = ({
	isSelected,
	vehicleClassKey,
}: TriggerVehicleClassSelectedEventProps): void => {
	const buttonAction = isSelected ? 'selected' : 'unselected';
	const formattedGAAction = `${pascalCase(`VehicleClass${vehicleClassKey}`)} ${buttonAction}`;
	const eventPayload = getSnowplowEventPayload({
		category: FILTERING,
		name: `dlr_mrktplace_filter_vehicle_class_${vehicleClassKey.toLowerCase()}_${buttonAction}_clicked`,
		schemaName: SNOWPLOW_EVENTS.CLICK.SCHEMA,
		version: SNOWPLOW_EVENTS.CLICK.VERSION,
	});

	logAnalyticsEvent({ eventPayload });
	logAnalyticsEvent({
		eventPayload: {
			action: formattedGAAction,
			category: FILTERING,
			isLegacyEvent: false,
			label: '',
		},
		provider: GOOGLE_ANALYTICS,
	});
};

export const triggerShowVehiclesButtonClickedEvent = (): void => {
	const eventPayload = getSnowplowEventPayload({
		category: FILTERING,
		name: 'dlr_mrktplace_filter_show_vehicles_clicked',
		schemaName: SNOWPLOW_EVENTS.CLICK.SCHEMA,
		version: SNOWPLOW_EVENTS.CLICK.VERSION,
	});

	logAnalyticsEvent({ eventPayload });
	logAnalyticsEvent({
		eventPayload: {
			action: 'Show vehicles button clicked',
			category: FILTERING,
			isLegacyEvent: false,
			label: '',
		},
		provider: GOOGLE_ANALYTICS,
	});
};

interface TriggerAdditionalFiltersToggleClickEventProps {
	filter: string;
	isToggleSelected: boolean;
}

export const triggerAdditionalFiltersToggleClickEvent = ({
	filter,
	isToggleSelected,
}: TriggerAdditionalFiltersToggleClickEventProps): void => {
	const label = formatText(isToggleSelected);
	const eventPayload = getSnowplowEventPayload({
		category: FILTERING,
		customData: { label },
		name: `dlr_mrktplace_filter_${snakeCase(filter)}_toggle_clicked`,
		schemaName: SNOWPLOW_EVENTS.CLICK.SCHEMA,
		version: SNOWPLOW_EVENTS.CLICK.VERSION,
	});

	logAnalyticsEvent({ eventPayload });
	logAnalyticsEvent({
		eventPayload: {
			action: `${splitCamelCase(filter)} toggle clicked`,
			category: FILTERING,
			isLegacyEvent: false,
			label,
		},
		provider: GOOGLE_ANALYTICS,
	});
};
