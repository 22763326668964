import T from 'i18n-react';
import _difference from 'lodash/difference';
import isEqual from 'lodash/isEqual';
import isObject from 'lodash/isObject';
import transform from 'lodash/transform';

import texts from 'Components/texts.json';
import { event } from 'Services/gtm';
import { capitaliseSentence, getBrowserLang, isArray, isNil, isNumber } from 'Utilities/helpers';
import Observable from 'Utilities/Observable';
import ROUTES from 'Utilities/routes';

T.setTexts(texts);

export const GAConstants = {
	category: {
		filtering: 'Filtering',
		list: 'Vehicle list',
		sorting: 'Sorting',
	},
};

export const gaVehicleList = (action = '', label = '', category = GAConstants.category.filtering) =>
	event({
		action,
		event_action: action,
		event_category: category,
		event_label: label,
	});

export const joinSortParams = ({ order, sort }) => [sort, order].join('-');

export const formatAsSelectOptions = ({ options = [], text, textSuffix, value }) =>
	options.map((option) => ({
		text: option?.[text],
		value: option?.[value],
		...(textSuffix && { textSuffix: `(${option?.[textSuffix]})` }),
	}));

export const compactValue = (value) => (isArray(value) ? Boolean(value.length) : Boolean(value));

export function difference(object, base) {
	return transform(object, (result, value, key) => {
		if (!isEqual(value, base[key])) {
			// eslint-disable-next-line no-param-reassign
			result[key] = isObject(value) && isObject(base[key]) ? difference(value, base[key]) : value;
		}
	});
}

export const defaultFilters = {
	ageFrom: null,
	ageTo: null,
	displayPriceFrom: null,
	displayPriceTo: null,
	excludeVehiclesOnFinance: false,
	exteriorGrade: [],
	fuel: [],
	includeSold: false,
	includeUnderOffer: false,
	isAvailableForCollection: false,
	make: [],
	maxDistance: null,
	mileageFrom: null,
	mileageTo: null,
	model: [],
	previousKeepersCountFrom: null,
	previousKeepersCountTo: null,
	selectedVehicle: null,
	serviceHistory: [],
	includeOnlyInternalTestingVehicles: false,
	transmission: [],
	vehicleClass: [],
};

export const getDefaultFilters = () => ({
	...defaultFilters,
});

export const getVehiclesCount = (translation, value) => {
	const lang = getBrowserLang();
	let count = parseInt(value);
	count = !Number.isNaN(count) ? count : value;

	let finalCount = count;
	let ending = 's';

	if (isNumber(finalCount)) {
		finalCount = count.toLocaleString(lang);
		ending = count === 1 ? '' : 's';
	} else {
		finalCount = '–';
	}

	return `${T.translate(translation, { count: finalCount })}${ending}`;
};

export const arrayNoEmptyValue = (arr) => !arr.some((item) => isNil(item));

const getValidMakesFromQuery = ({ makes = [], query = {} }) => {
	const makeSlugs = makes.map((m) => m.slug) || [];
	const validMakes = makes.filter((make) => query?.make?.includes(make.slug));
	const hasInvalidMakes = Boolean(_difference(query?.make, makeSlugs).length);

	return { hasInvalidMakes, validMakes };
};

export const getMetaTitleFromQuery = ({ makes, query }) => {
	const { validMakes } = getValidMakesFromQuery({ makes, query });
	const makeSlug = validMakes?.length === 1 ? validMakes[0]?.name : '';
	const title = makeSlug ? capitaliseSentence(makeSlug) : T.translate('metaTags.pages.vehicleList.titlePrefix');

	return T.translate('metaTags.pages.vehicleList.title', { title });
};

export const isPageValid = ({ makes, query }) => {
	// if some existing make in query doesn't match with list from server we will return 404
	// TODO: We should sanitise the query string instead of showing a 404 page
	const { hasInvalidMakes } = getValidMakesFromQuery({ makes, query });
	return !makes?.length || !hasInvalidMakes;
};

export const checkIsVehicleListPage = () => {
	const { as } = ROUTES.VEHICLE_LIST;
	return window.location?.pathname === as?.();
};

/*
 * This observable was added to allow multi component communication
 * for vehicle list refreshing.
 * This could be also achieved by hooking into zustand store and
 * resetting filters from there. However zustand stores are created as react
 * hooks and these cannot work with class components directly.
 * Components required to refresh the list were created as class components
 * therefore observable was chosen to allow multi component communication.
 */
export const vehicleListRefreshObservable = new Observable();
