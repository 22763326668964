import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { devtools } from 'zustand/middleware';

import { createGenericStoreWithDevtools } from 'Stores/store';
import { isDev } from 'Utilities/helpers';
import usePrevious from 'Utilities/hooks/usePrevious';
import ROUTES from 'Utilities/routes';

import type { VehiclePaymentDataStore } from './VehiclePaymentDataStore.types';

const createVehiclePaymentDataStore = () =>
	devtools<VehiclePaymentDataStore>(
		(set, get) => ({
			_get: get,
			cachedAt: null,
			commit(cacheKey, state) {
				set({
					cachedAt: +new Date(),
					key: cacheKey,
					state: { ...get().state, ...state },
				});
			},
			getState(cacheKey) {
				const { key, state } = get();

				const shouldReturnFromCache = key === cacheKey;

				if (shouldReturnFromCache) {
					return state;
				}

				return null;
			},
			key: null,
			reset() {
				set({
					cachedAt: null,
					key: null,
					state: null,
				});
			},
			state: null,
		}),
		{ enabled: isDev, name: 'VehiclePaymentDataStore' },
	);

export const vehiclePaymentDataStore = createGenericStoreWithDevtools(createVehiclePaymentDataStore()).getState();

export const useVehiclePaymentDataStoreRouterEffect = (): void => {
	const router = useRouter();
	const prevRouteAsPath = usePrevious(router.asPath);

	useEffect(() => {
		const onRouteChangeComplete = (asPath: string) => {
			const shouldResetStore =
				ROUTES.VEHICLE_PAYMENT.like &&
				ROUTES.VEHICLE_PAYMENT.like(prevRouteAsPath) &&
				!ROUTES.VEHICLE_PAYMENT.like(asPath);

			// Reset the store if we leave the Vehicle Payment page
			if (shouldResetStore) {
				vehiclePaymentDataStore.reset();
			}
		};

		router.events?.on?.('routeChangeComplete', onRouteChangeComplete);

		return () => {
			router.events?.off?.('routeChangeComplete', onRouteChangeComplete);
		};
	}, [prevRouteAsPath, router.events]);
};
