import Cookies from 'js-cookie';
import type { NextRequest } from 'next/server';

import { getIsomorphicCookie, stringBooleanToBoolean } from 'Utilities/helpers';

import type { FEATURES } from './FeaturesContextConsts';
import { FEATURES_ARRAY } from './FeaturesContextConsts';

export type FeatureToggleKeys = keyof typeof FEATURES;
export type FeatureToggleValue = boolean | string;

type Features = Record<string, string | boolean>;

type ValidFeatures = {
	-readonly [Property in FeatureToggleKeys]?: boolean;
};

interface GetValidFeatureTogglesProps {
	availableFeatures?: string[];
	features: Features;
}

export const getValidFeatureToggleValue = (featureToggleValue: FeatureToggleValue): boolean => {
	if (typeof featureToggleValue === 'string') {
		return stringBooleanToBoolean(featureToggleValue);
	}
	return Boolean(featureToggleValue);
};

export const getValidFeatureToggles = ({
	availableFeatures = FEATURES_ARRAY,
	features,
}: GetValidFeatureTogglesProps): ValidFeatures =>
	Object.entries(features).reduce((acc: ValidFeatures, [featureName, featureValue]) => {
		if (availableFeatures.includes(featureName)) {
			acc[featureName as FeatureToggleKeys] = getValidFeatureToggleValue(featureValue);
		}
		return acc;
	}, {});

export const setFeatureToggleCookies = (features: ValidFeatures): void => {
	Object.entries(features).forEach(([feature, value]) => {
		Cookies.set(feature, value.toString());
	});
};

export const getFeatureTogglesFromQueryString = (availableFeatures = FEATURES_ARRAY): ValidFeatures => {
	const query = new URLSearchParams(window.location.search);
	const featuresFromQueryString = Object.fromEntries(query.entries());
	return getValidFeatureToggles({ availableFeatures, features: featuresFromQueryString });
};

export const isFeatureEnabled = (featureName: string, req?: NextRequest): boolean =>
	stringBooleanToBoolean(getIsomorphicCookie(featureName, req));
