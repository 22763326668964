/* eslint-disable local-rules/function-naming */
import type { AxiosError, AxiosInstance } from 'axios';
import Axios from 'axios';
import isObject from 'lodash/isObject';
import type { NextPageContext } from 'next';

import { DealerError } from 'Utilities/error/errors';
import { handleAxiosError } from 'Utilities/error/handleAxiosError';

import {
	apiClientAxiosOnFulfilled,
	apiClientAxiosOnRejected,
	apiClientAxiosOnRequestSetAuthTokenHeader,
	apiClientAxiosOnRequestSetAuthTokenWithBearerHeader,
	isAxiosResponse,
} from './apiClient.helpers';
import type {
	ApiErrorResponseForJsend,
	ApiFetchFunction,
	CreateAxiosInstanceForServiceFn,
	IsomorphicApiV2Apis,
	QueryServiceParams,
} from './apiClient.types';

/**
 * The default error response that is returned when an error occurs.
 * This is used to ensure that the response always has a message and status.
 */
const defaultErrorResponse: ApiErrorResponseForJsend = {
	message: 'An error occurred',
	status: 'error',
};

/**
 * Used to create an axios instance with the provided parameters.
 *
 * @example
 * const fetcher = createAxiosInstanceForService({
 * 	baseURL: 'https://api.example.com',
 * 	headers: {
 * 		'Content-Type': 'application/json',
 * 	},
 * });
 */
export const createAxiosInstanceForService: CreateAxiosInstanceForServiceFn = (params, accessConfig): AxiosInstance => {
	const axios = Axios.create({
		...params,
		baseURL: params.baseURL,
	});

	axios.interceptors.request.use(
		accessConfig?.withBearer
			? apiClientAxiosOnRequestSetAuthTokenWithBearerHeader
			: apiClientAxiosOnRequestSetAuthTokenHeader,
	);

	axios.interceptors.response.use(apiClientAxiosOnFulfilled, apiClientAxiosOnRejected);

	return axios;
};

/**
 * Used to create an axios instance for a third party service.
 *
 * @example
 * const fetcher = createPureAxiosInstance( 'https://api.example.com');
 */

export const createPureAxiosInstance = (baseURL: string): AxiosInstance => {
	const axios = Axios.create({
		baseURL,
	});

	return axios;
};

/**
 * The isomorphicApiV2 function is used to make an isomorphic request to the API.
 * It will use the browser function if the request is made on the client,
 * and the server function if the request is made on the server.
 *
 * We previously used the `isomorphicApi` function, which dynamically imported `dataProvider`,
 * but this isn't necessary anymore as serverless functions are properly isolated from the client environment.
 *
 * @example
 * export const getVehicle: ApiServiceFunction<{ id: string }, Vehicle> = async ({ config, ctx, params }) =>
 * 	isomorphicApiV2(ctx, {
 * 		browser: async () => {
 * 			return axios.get(
 * 				`${API_ROUTES.VEHICLE}/${params.id}`,
 * 				config,
 * 			);
 * 		},
 * 		server: async () => {
 * 			return VehicleService.getVehicle({
 * 				config,
 * 				params: {
 * 					id: params.id,
 * 				},
 * 			});;
 * 		},
 * 	});
 *
 */
export const isomorphicApiV2 = async <ResultBrowser = unknown, ResultServer = unknown>(
	ctx: NextPageContext | undefined,
	{ browser, server }: IsomorphicApiV2Apis<ResultBrowser, ResultServer>,
): Promise<ResultBrowser | ResultServer> => {
	const { isSSR } = ctx || {};

	if (isSSR) {
		return server();
	}

	const res = await browser();

	if (isAxiosResponse(res)) {
		// If the response is an AxiosResponse, we need to extract the data from it.
		return res.data;
	}

	return res;
};

/**
 * The queryService function is used to create a fetch function for a specific service.
 * It will handle the response from the service and return the data or an error response.
 * It will also handle any errors that occur when making the request to the service.
 *
 * @example
 * export const getVehicle = queryService({
 * 	key: 'getVehicle',
 * 	query: async ({ config, params }) => {
 * 		return axios.get(`/vehicles/${params.id}`, config);
 * 	},
 * });
 */
export const queryService =
	<Params = NoParams, Success = unknown, Failure = UnknownShapeObject>({
		key,
		onError,
		query,
		select,
		serviceErrorConstructor,
	}: QueryServiceParams<Params, Success>): ApiFetchFunction<Params, Success | Failure> =>
	async (props) => {
		try {
			const { data } = await query(props);

			if (typeof select === 'function') {
				return select(data, props);
			}

			return data;
		} catch (e) {
			const onErrorReturn = await onError?.(e, props);

			if (isObject(onErrorReturn)) {
				return onErrorReturn;
			}

			const { environment } = props;

			/**
			 * If the call originates from the client and an error occurs, we want to throw the error to be handled by the middleware in the proxy layer.
			 * This also allows us to retain status code of the response and the original error message.
			 */
			if (environment === 'client') {
				throw e;
			}

			const ErrorConstructor = serviceErrorConstructor || DealerError;

			handleAxiosError({
				axiosError: e as AxiosError,
				sourceError: new ErrorConstructor(key),
			});

			const resp = (e as AxiosError).response?.data;

			return (resp || defaultErrorResponse) as Failure;
		}
	};
