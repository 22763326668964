import { getSnowplowEventPayload, logAnalyticsEvent } from 'Services/analytics/analytics';
import { ANALYTICS_CATEGORIES, SNOWPLOW_EVENTS } from 'Services/analytics/analytics.const';

const { EXTERIOR_GRADING } = ANALYTICS_CATEGORIES;

const { CLICK } = SNOWPLOW_EVENTS;

interface TriggerGradingTierExpandedOrCollapsedEventParams {
	grade: string;
	isExpand: boolean;
}

export const triggerGradingTierExpandedOrCollapsedEvent = ({
	grade,
	isExpand,
}: TriggerGradingTierExpandedOrCollapsedEventParams): void => {
	const eventPayload = getSnowplowEventPayload({
		category: EXTERIOR_GRADING,
		customData: { label: grade },
		name: `dlr_grading_tier_${isExpand ? 'expanded' : 'collapsed'}`,
		schemaName: CLICK.SCHEMA,
		version: CLICK.VERSION,
	});

	logAnalyticsEvent({ eventPayload });
};
