import dayjs from 'dayjs';
import type { NextPageContext } from 'next';

import genericModels from 'Mirage/fixtures/genericModels';
import makesMock from 'Mirage/fixtures/makes';
import { getMockSaleTimes } from 'Mirage/utilities/sale.helpers';
import { getFeatureFlags } from 'Services/api/featureFlags';
import { getOptimizelyDatafile } from 'Services/api/optimizely';
import type { FeatureFlags } from 'Services/dataProvider.types';
import type { ConfigDatafile } from 'Services/optimizely/optimizely';
import type { Makes } from 'Types/make';
import type { Model } from 'Types/model';
import type { SaleTimes } from 'Types/saleTimes';

import fetchMakesWithExceptionLogger from './fetchMakes';
import fetchModelsWithExceptionLogger from './fetchModels';
import fetchSaleTimeWithExceptionLogger from './fetchSaleTime';

interface AppWideData {
	featureFlags: FeatureFlags;
	makes: Makes;
	models: Omit<Model, 'total'>[];
	optimizelyDatafile: ConfigDatafile;
	saleTimes: SaleTimes;
}

const fetchAppWideData = async (context: NextPageContext, isMirageEnabled: boolean): Promise<AppWideData> => {
	if (isMirageEnabled) {
		const makes = makesMock;
		const mockedDateTime = dayjs(context?.req?.cookies?.mockedDateTime);
		const saleTimes = getMockSaleTimes(mockedDateTime);
		const models = genericModels;
		const optimizelyDatafile = {};
		// As this is loaded on SSR we can't use Mirage, so we mock the data here instead
		return { featureFlags: {}, makes, models, optimizelyDatafile, saleTimes };
	}

	const promises = [
		fetchMakesWithExceptionLogger(context, { include: 'enquiryCount' }),
		fetchModelsWithExceptionLogger(context),
		fetchSaleTimeWithExceptionLogger(context),
		getFeatureFlags(context),
		getOptimizelyDatafile({ ctx: context }),
	];

	const responses = await Promise.allSettled(promises);
	const [makes, models, saleTimes, featureFlags, optimizelyDatafile] = responses.map(
		(res) => res.status === 'fulfilled' && res.value,
	);

	return { featureFlags, makes, models, optimizelyDatafile, saleTimes };
};

export default fetchAppWideData;
