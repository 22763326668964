import type { IncomingMessage, ServerResponse } from 'http';
import { stdSerializers } from 'pino-http';

import { isDev, isStaging } from '../../utilities/serverUtils';

export const PINO_LOG_LEVEL = {
	debug: 'debug',
	error: 'error',
	info: 'info',
	trace: 'trace',
	warn: 'warn',
} as const;

type LogLevel = (typeof PINO_LOG_LEVEL)[keyof typeof PINO_LOG_LEVEL];

const devConfig = {
	level: PINO_LOG_LEVEL.debug,
	transport: {
		options: {
			colorize: true,
			singleLine: true,
		},
		target: 'pino-pretty',
	},
};

const defaultLevel = isDev || isStaging ? PINO_LOG_LEVEL.info : PINO_LOG_LEVEL.debug;

export const PINO_CONFIG = {
	customLevels: {
		critical: 100,
	},
	customLogLevel(req: IncomingMessage, res: ServerResponse, err?: Error): LogLevel {
		if (res.statusCode >= 400 && res.statusCode < 500) {
			return PINO_LOG_LEVEL.warn;
		}
		if (res.statusCode >= 500 || err) {
			return PINO_LOG_LEVEL.error;
		}
		return PINO_LOG_LEVEL.trace;
	},
	formatters: {
		level(label: string): { level: string } {
			return { level: label };
		},
	},
	level: defaultLevel,
	redact: [
		'req.headers.authorization',
		'req.headers["x-mway-user"]',
		'req.headers.cookie',
		'headers.auth',
		'auth.password',
		'verificationCode',
	],
	serializers: { ...stdSerializers, error: stdSerializers.err },
	...(isDev ? devConfig : {}),
};
