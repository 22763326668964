import useVehicleFilterStore, {
	useHasUserSetSort,
	useSetHasUserSetSort,
} from 'Stores/FilterStore/VehicleListFilter/VehicleListFilterStore';
import { useListType } from 'Stores/ListStore/ListStore';
import useFeatureToggle, { FEATURES } from 'Utilities/hooks/useFeatureToggle';

import {
	VALID_BID_COUNTS_LIST_TYPES,
	VALID_RECOMMENDED_LIST_TYPES,
	VEHICLE_SORT_CONDITIONAL_OPTIONS,
	VEHICLE_SORT_OPTIONS,
	VEHICLE_SORT_OPTIONS_WITH_CONDITIONAL,
} from './useSortOrder.const';
import { getOrderFromSort, isValidOrderParamValue, isValidSortParamValue } from './useSortOrder.helpers';
import type { SortOption, UseSortOrder } from './useSortOrder.types';

const useSortOptions = (): readonly SortOption[] => {
	const isBidCountsSortOrderEnabled = useFeatureToggle(FEATURES.bidCountsSort);
	const listType = useListType();

	const isValidBidCountsListType = VALID_BID_COUNTS_LIST_TYPES.some(
		(validRecommendedListType) => validRecommendedListType === listType,
	);
	const isValidRecommendedListType = VALID_RECOMMENDED_LIST_TYPES.some(
		(validRecommendedListType) => validRecommendedListType === listType,
	);
	const isAllowedToUseBidCountsSortOrder = isBidCountsSortOrderEnabled && isValidBidCountsListType;

	if (!isAllowedToUseBidCountsSortOrder && !isValidRecommendedListType) {
		return VEHICLE_SORT_OPTIONS;
	}

	return VEHICLE_SORT_OPTIONS_WITH_CONDITIONAL.filter((option) => {
		if (option.sort === VEHICLE_SORT_CONDITIONAL_OPTIONS.recommended) {
			return isValidRecommendedListType;
		}

		if (option.sort === VEHICLE_SORT_CONDITIONAL_OPTIONS.bidCounts) {
			return isBidCountsSortOrderEnabled;
		}

		return true;
	});
};

const useSortOrder = (): UseSortOrder => {
	const { order, sort } = useVehicleFilterStore((store) => store.filters);
	const hasUserSetSort = useHasUserSetSort();
	const setHasUserSetSort = useSetHasUserSetSort();
	const sortOptions = useSortOptions();

	const defaultSort = sortOptions[0].sort;
	const defaultOrder = sortOptions[0].order;

	// Gets a valid combination of sort/order and checks against flags
	const isValidSort = isValidSortParamValue({ sort, sortOptions });
	let adjustedSort;

	// To persist first sort option when user hasn't selected a sort option yet
	if (!hasUserSetSort) {
		adjustedSort = defaultSort;
	} else if (isValidSort) {
		adjustedSort = sort;
	} else {
		// Invalid sort provided
		setHasUserSetSort(false);
		adjustedSort = defaultSort;
	}

	const isValidOrder = isValidOrderParamValue({ adjustedSort, order, sortOptions });
	const adjustedOrder = isValidOrder ? order : getOrderFromSort({ adjustedSort, sortOptions });

	return { defaultOrder, defaultSort, order: adjustedOrder, sort: adjustedSort, sortOptions };
};

export default useSortOrder;
