import { useRef } from 'react';
import type { BrowserTracker } from '@snowplow/browser-tracker';
import { setUserId } from '@snowplow/browser-tracker';

import { initSnowplowTracker } from 'Services/snowplow';
import type { User } from 'Types/user';
import { isSSR } from 'Utilities/helpers';
import useFeatureToggle, { FEATURES } from 'Utilities/hooks/useFeatureToggle';

export const useInitSnowplow = (user?: User): void => {
	const tracker = useRef<Nullable<BrowserTracker>>(null);
	const userId = useRef<Nullable<User['id']> | undefined>(null);
	const isSnowplowMiniRedirect = useFeatureToggle(FEATURES.snowplowMiniRedirect);

	if (isSSR()) {
		return;
	}

	if (!tracker.current) {
		tracker.current = initSnowplowTracker({ isSnowplowMiniRedirect });
	}

	if (userId.current !== user?.id) {
		setUserId(user?.id ? `${user?.id}` : undefined);
		userId.current = user?.id;
	}
};
