import isUndefined from 'lodash/isUndefined';

import { getSchemaURL, getSnowplowEventPayload, logAnalyticsEvent } from '../../analytics';
import { SNOWPLOW_EVENTS } from '../../analytics.const';
import type { GetSnowplowEventPayloadProps, SimilarVehiclesContext, SnowplowEventPayload } from '../../analytics.types';

export const createSnowplowEventFunctions = (
	category: string,
): Record<string, (name: string, customData?: UnknownShapeObject) => void> => {
	const eventFunction = <CustomData extends UnknownShapeObject>({
		customData,
		name,
		schemaName,
		version,
	}: Pick<GetSnowplowEventPayloadProps<CustomData>, 'customData' | 'name' | 'schemaName' | 'version'>): void => {
		const eventPayload = getSnowplowEventPayload({
			category,
			...(!isUndefined(customData) && { customData }),
			name,
			schemaName,
			version,
		});

		logAnalyticsEvent({ eventPayload });
	};

	return {
		[`trigger${category}ClickEvent`]: (name: string, customData?: UnknownShapeObject): void => {
			eventFunction({
				customData,
				name,
				schemaName: SNOWPLOW_EVENTS.CLICK.SCHEMA,
				version: SNOWPLOW_EVENTS.CLICK.VERSION,
			});
		},
		[`trigger${category}LoadEvent`]: (name: string, customData?: UnknownShapeObject): void => {
			eventFunction({
				customData,
				name,
				schemaName: SNOWPLOW_EVENTS.LOAD.SCHEMA,
				version: SNOWPLOW_EVENTS.LOAD.VERSION,
			});
		},
	};
};

export const getContextForSimilarVehicles = ({
	enquiryIds,
	version,
}: SimilarVehiclesContext): SnowplowEventPayload['context'] => {
	if (!version && !enquiryIds) {
		return undefined;
	}

	return [
		{
			data: {
				enquiry_ids: enquiryIds,
				model_version: version,
			},
			schema: getSchemaURL(SNOWPLOW_EVENTS.PRO_SIMILAR_VEHICLES_CAROUSEL),
		},
	];
};
