import { isomorphicApiV2 } from 'Services/motorway/apiClient';
import { NOT_IMPLEMENTED_RESPONSE } from 'Services/motorway/apiClient.responses';
import type { ApiServiceFunction } from 'Services/motorway/apiClient.types';
import * as OptimizelyService from 'Services/motorway/services/optimizely/optimizely';
import type { ConfigDatafile } from 'Services/optimizely/optimizely';

export const getOptimizelyDatafile: ApiServiceFunction<NoParams, ConfigDatafile> = async ({ ctx }) =>
	isomorphicApiV2(ctx, {
		browser: async () => {
			return NOT_IMPLEMENTED_RESPONSE;
		},
		server: async () => {
			return OptimizelyService.getOptimizelyDatafile({ environment: 'server' });
		},
	});
