import type { Makes } from 'Types/make';
import type { Models } from 'Types/model';

export const getMakesWithNoModels = ({ makes = [], models = [] }: { makes: Makes; models: Models }) => {
	const makeIdsWithModels = new Set(models.map((model) => model.makeId));

	const makesWithNoModels = makes.reduce<string[]>((acc, make) => {
		if (!makeIdsWithModels.has(make.id)) {
			acc.push(make.name.toLowerCase());
		}
		return acc;
	}, []);
	return makesWithNoModels;
};
