import { datadogRum } from '@datadog/browser-rum';

import getEnvironmentName from 'Utilities/helpers/getEnvironmentName';

import { getDatadogUserFromSessionUser } from './datadog.helpers';
import type {
	ApplySessionReplayMaskParams,
	DatadogRumProps,
	SessionReplayActionMaskAttributes,
	SessionReplayMaskAttributes,
	SessionReplayMaskLevel,
	SessionReplayPrivacyLevelAttributes,
} from './datadog.types';
import { DEFAULT_DATADOG_MASKED_EVENT_NAME, sessionReplayMaskLevel } from './datadogRum.const';

export const initDatadogRum = ({ release, user }: DatadogRumProps): void => {
	const userIdentity = getDatadogUserFromSessionUser(user);

	datadogRum.init({
		applicationId: '2326e377-b46a-458c-9bab-7bb3c702e1cf',
		clientToken: 'pub789b1c29e875a5c6e9d627b9b3f2de44',
		defaultPrivacyLevel: sessionReplayMaskLevel.maskUserInput,
		env: getEnvironmentName(),
		sampleRate: 100,
		service: 'dealer-webapp',
		sessionReplaySampleRate: 100,
		site: 'datadoghq.eu',
		trackFrustrations: true,
		trackInteractions: true,
		trackLongTasks: true,
		trackResources: true,
		version: release?.toLowerCase(),
	});

	datadogRum.setUser(userIdentity);
	datadogRum.startSessionReplayRecording();
};

// To understand the different levels of masking, refer to Datadog's Session Replay privacy options page:
// https://docs.datadoghq.com/real_user_monitoring/session_replay/privacy_options/
export const applySessionReplayPrivacyLevel = (
	maskLevel: SessionReplayMaskLevel = sessionReplayMaskLevel.mask,
): SessionReplayPrivacyLevelAttributes => ({
	'data-dd-privacy': maskLevel,
});

// Allows for more granular action names to be added if the parent element is masked
export const applySessionReplayActionName = (actionName: string): SessionReplayActionMaskAttributes => ({
	'data-dd-action-name': actionName,
});

export const applySessionReplayMask = (params?: ApplySessionReplayMaskParams): SessionReplayMaskAttributes => {
	const { maskedActionName = DEFAULT_DATADOG_MASKED_EVENT_NAME, maskLevel = sessionReplayMaskLevel.mask } =
		params || {};

	const shouldMaskActions = maskLevel !== sessionReplayMaskLevel.allow;

	return {
		...applySessionReplayPrivacyLevel(maskLevel),
		...(shouldMaskActions && applySessionReplayActionName(maskedActionName)),
	};
};
