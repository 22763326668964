import { getSnowplowEventPayload, logAnalyticsEvent } from '../../analytics';
import { ANALYTICS_PROVIDERS, SNOWPLOW_EVENTS } from '../../analytics.const';
import type { GAEventPayload } from '../../analytics.types';

import {
	VEHICLE_DETAILS_IMAGE_EVENTS,
	VEHICLE_DETAILS_IMAGE_SECTIONS,
	VEHICLE_DETAILS_IMAGE_ZOOM_LEVELS,
} from './galleryEvents.consts';
import type { ZoomEventsParams } from './galleryEvents.types';

const { GOOGLE_ANALYTICS } = ANALYTICS_PROVIDERS;

export const triggerDoubleClickOnImageEvent = ({ maxZoom, section, zoomLevel }: ZoomEventsParams): void => {
	const zoomLevelLabel = VEHICLE_DETAILS_IMAGE_ZOOM_LEVELS[`LEVEL_${zoomLevel}`];

	const snowplowEventPayload = getSnowplowEventPayload({
		category: VEHICLE_DETAILS_IMAGE_SECTIONS[section],
		customData: { label: zoomLevelLabel },
		name:
			zoomLevel === maxZoom
				? VEHICLE_DETAILS_IMAGE_EVENTS.ZOOM_CURSOR_IN.snowplow
				: VEHICLE_DETAILS_IMAGE_EVENTS.ZOOM_CURSOR_OUT.snowplow,
		schemaName: SNOWPLOW_EVENTS.CLICK.SCHEMA,
		version: SNOWPLOW_EVENTS.CLICK.VERSION,
	});

	const googleAnalyticsEventPayload: GAEventPayload = {
		action:
			zoomLevel === maxZoom
				? VEHICLE_DETAILS_IMAGE_EVENTS.ZOOM_CURSOR_IN.ga
				: VEHICLE_DETAILS_IMAGE_EVENTS.ZOOM_CURSOR_OUT.ga,
		category: VEHICLE_DETAILS_IMAGE_SECTIONS[section],
		isLegacyEvent: false,
		label: zoomLevelLabel,
	};

	logAnalyticsEvent({ eventPayload: snowplowEventPayload });
	logAnalyticsEvent({ eventPayload: googleAnalyticsEventPayload, provider: GOOGLE_ANALYTICS });
};
