import type { Dayjs } from 'dayjs';

import type { SaleTimes } from 'Types/saleTimes';
import dayjs from 'Utilities/dayjs';

export enum SaleEventHour {
	liveSaleEnd = 15,
	liveSaleStart = 19,
}

export enum SaleEventMinute {
	liveSaleEnd = 30,
	liveSaleStart = 0,
}

export enum SaleEventFormat {
	liveSaleEnd = 'YYYY-MM-DDT15:30:00.000',
	liveSaleStart = 'YYYY-MM-DDT19:00:00.000',
}

const defaultSaleValues = {
	hasReceivedSaleTimes: true,
	isSaleActive: false,
	isSaleLaterToday: false,
	millisecondsRemaining: 0,
	nextSaleStartsAt: '',
	saleClosingHour: '',
	saleClosingMinute: '',
	todaysSaleEndsAt: null,
	todaysSaleStartedAt: null,
};

const formatDayjsDate = (dayjsDate: Dayjs, format: string) => dayjs(dayjsDate)?.format?.(format) || '';

const getSaleEventTime = (dayjsDate: Dayjs, hour: SaleEventHour, minute: SaleEventMinute) =>
	dayjsDate.hour(hour).minute(minute).startOf('minute');

const getLiveSaleValues = (dayjsDate: Dayjs) => {
	const isSecondDayOfSale = dayjsDate.hour() < 19;

	const todaySaleStart = getSaleEventTime(
		isSecondDayOfSale ? dayjsDate.subtract(1, 'day') : dayjsDate,
		SaleEventHour.liveSaleStart,
		SaleEventMinute.liveSaleStart,
	);

	const todaySaleEnd = isSecondDayOfSale
		? getSaleEventTime(dayjsDate, SaleEventHour.liveSaleEnd, SaleEventMinute.liveSaleEnd)
		: getSaleEventTime(dayjsDate.add(1, 'day'), SaleEventHour.liveSaleEnd, SaleEventMinute.liveSaleEnd);
	const isSaleActive = dayjsDate.isBetween?.(todaySaleStart, todaySaleEnd, null, '[)');
	const isBeforeSaleStart = dayjsDate.isBefore?.(todaySaleStart);

	const nextDaySale = isSecondDayOfSale
		? getSaleEventTime(dayjsDate, SaleEventHour.liveSaleStart, SaleEventMinute.liveSaleStart)
		: getSaleEventTime(dayjsDate.add(1, 'day'), SaleEventHour.liveSaleStart, SaleEventMinute.liveSaleStart);

	const nextSaleBasedOnCurrentTime = isBeforeSaleStart ? todaySaleStart : nextDaySale;
	const nextSaleStartsAtDate = dayjs(isSaleActive ? nextDaySale : nextSaleBasedOnCurrentTime);

	const todaysSaleStartedAt = isSaleActive ? formatDayjsDate(todaySaleStart, SaleEventFormat.liveSaleStart) : null;
	const todaysSaleEndsAt = isSaleActive ? formatDayjsDate(todaySaleEnd, SaleEventFormat.liveSaleEnd) : null;
	const nextSaleStartsAt = formatDayjsDate(nextSaleStartsAtDate, SaleEventFormat.liveSaleStart);

	const millisecondsRemaining = dayjs(isSaleActive ? todaysSaleEndsAt : nextSaleStartsAt).diff(dayjsDate);

	return {
		isSaleActive,
		isSaleLaterToday: isSecondDayOfSale,
		millisecondsRemaining,
		nextSaleStartsAt,
		saleClosingHour: '3pm',
		saleClosingMinute: '30',
		todaysSaleEndsAt,
		todaysSaleStartedAt,
	};
};

export const getMockSaleTimes = (dayjsDate = dayjs()): SaleTimes => {
	if (!dayjs.isDayjs(dayjsDate)) {
		return defaultSaleValues;
	}

	const liveSale = getLiveSaleValues(dayjsDate);

	return { ...liveSale, hasReceivedSaleTimes: true };
};
