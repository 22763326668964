import axios from '../axios';

import type { ApiSchemaShortlistEnquiry, ApiSchemaUnshortlistEnquiries } from './schema.types';

export const loadShortlist = async (): Promise<ApiSchemaShortlistEnquiry[]> => {
	const res = await axios.get('/api/shortlisted-enquiries');
	return res.data;
};

export const addToShortlist = async (enquiryId: number): Promise<ApiSchemaShortlistEnquiry> => {
	const res = await axios.post(`/api/shortlisted-enquiries/${enquiryId}/shortlist`);
	return res.data;
};

export const removeFromShortlist = async (enquiryId: number): Promise<ApiSchemaShortlistEnquiry> => {
	const res = await axios.post(`/api/shortlisted-enquiries/${enquiryId}/unshortlist`);
	return res.data;
};

export const removeEnquiriesFromShortlist = async (
	enquiryIds: number[] | string[],
): Promise<ApiSchemaUnshortlistEnquiries> => {
	const res = await axios.post('/api/shortlisted-enquiries/unshortlist-enquiries', {
		enquiryIds: enquiryIds.map((enquiryId) => parseInt(enquiryId as string)),
	});
	return res.data;
};
